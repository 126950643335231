
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase {
  service = new RelatorioService();
  item = new Relatorio();
  overlay: boolean = false;
  filter: any = {};

  mounted() {
    this.filter = this.$route.query;
    this.overlay = true;

   this.service.GetRelatorioCobrancas(this.filter.empresaId, this.filter.dataEmissaoInicial, this.filter.dataEmissaoFinal) 
      .then(res => {
        this.item = res.data; 
      })
      .catch(err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      })
      .finally(() => {
        this.overlay = false;
      });
  }
}
class Relatorio {
    public nomeClienteECpf:string = "";
    public numeroContrato:number = 0;
    public numeroParcela:number = 0;
    public empresa:string = "";
    public dataEnvioCobranca:string = "";
    public dataEnvioSms:string = "";
    public numeroTelefone:string = "";
    public dataEnvioWpp:string = "";
}
